<template>
  <div class="menber-dialog">
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="600px"
    >
      <el-form ref="form" label-width="210px">
        <el-form-item label="土地类型:" style="width: 300px">
          {{ currentItem.landType }}
        </el-form-item>
        <el-form-item label="托管人:">
          {{ currentItem.farmerName }}
        </el-form-item>
        <el-form-item label="电话:">
          {{ currentItem.farmerPhone }}
        </el-form-item>
        <el-form-item label="地址:">{{
          currentItem.areaName
        }}</el-form-item>
        <el-form-item label="接管类型:">
          {{ currentItem.orderType == 1 ? "全程托管" : "环节托管" }}
        </el-form-item>
        <el-form-item label="种养品种:">
          {{ currentItem.cropCode }}
        </el-form-item>
        <el-form-item label="托管环节:">
          {{ currentItem.orderContent }}
        </el-form-item>
        <el-form-item label="接管时间:">
          {{ currentItem.startDate?currentItem.startDate.substr(0, 10):'' }}-{{ currentItem.endDate?currentItem.startDate.substr(0, 10):'' }}
        </el-form-item>
        <el-form-item label="接管面积:">
          {{ currentItem.landArea }}亩
        </el-form-item>
        <el-form-item label="单价:">
          {{ currentItem.univalentPrice }}元
        </el-form-item>
        <el-form-item label="总价:">
          {{ currentItem.estimatePrice }}元
        </el-form-item>
        <el-form-item label="产权凭证:">
          <p
            v-for="(item, index) in currentItem.pzzp"
            :key="index"
            class="pdfStyle"
          >
            {{ item.name }}
            <i
              class="el-icon-download"
              title="下载"
              @click="download(item)"
            ></i>
          </p>
            <!-- <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.pzzp"
            :key="index"
            :preview-src-list="currentItem.pzzp"
          >
          </el-image> -->
        </el-form-item>
        <el-form-item label="现场照片:">
          <el-image
            style="width: 100px; height: 100px"
            :src="item"
            v-for="(item, index) in currentItem.xczp"
            :key="index"
            :preview-src-list="currentItem.xczp"
          >
          </el-image>
        </el-form-item>
        <el-form-item label="其他说明:">
          {{ currentItem.remark }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
  <script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogVisible: true,
      baseUrl: window.globalUrl.BASE_API,
      currentItem: {},
    };
  },
  mounted() {},
  computed: mapGetters(["userInfo"]),
  watch: {},
  methods: {
    // 下载
    download(item) {
      const url = item.urlView;
      let dom = document.createElement("a");
      dom.style.display = "none";
      dom.href = url;
      dom.setAttribute("download", item.name);
      document.body.appendChild(dom);
      dom.click();
    },
    // 详情回显
    init(obj) {
      let pzzp = [];
      if (obj.landCertificateUrl) {
        JSON.parse(obj.landCertificateUrl).forEach((item) => {
          pzzp.push({
            ...item,
            urlView: this.baseUrl + "admin/file/get?ossFilePath=" + item.url,
          });
        });
      }
      obj.pzzp = pzzp;
      let xczp = [];
      if (obj.livePhotos) {
        JSON.parse(obj.livePhotos).forEach((item) => {
          xczp.push(this.baseUrl + "admin/file/get?ossFilePath=" + item.url);
        });
      }
      obj.xczp = xczp;
      this.currentItem = obj;
    },
    back() {
      this.$emit("closeDialog2", false);
    },
    handleClose() {
      this.$emit("closeDialog2", false);
    },
  },
};
</script>
  <style lang="less" scoped>
/deep/.el-form-item {
  margin-bottom: 0 !important;
  .el-input {
    width: 260px;
  }
}
/deep/.el-dialog__body {
  padding-left: 30px;
}
.pdfStyle {
  margin: 0;
  background-color: #f5f7fa;
  color: #409eff;
  margin-bottom: 4px;
  height: 32px;
  width: 90%;
  line-height: 32px;
  text-indent: 8px;
  i {
    cursor: pointer;
    float: right;
    font-size: 20px;
    margin: 6px;
  }
}
</style>
  